<script>
	import {Contract, data as data_contracts } from "../../stores/contracts";
	import {state, data, useAlchemy,
		mintBento,
		mintRonin,
		claimRonin,
		claimKitsunes,
	} from "../../stores/manager";
	import {data as data_alchemy} from "../../stores/web3/alchemy/contracts";
	import {unlock, lock} from "../../stores/web3/wallet";


	var keys = [];

	$:{
		keys = Object.keys($data_alchemy);
		// console.log('keys : ', keys )
	}

	const toggle = () => {show = !show}

	function doUnlock(){
		unlock();
	}
	function doLock(){
		lock();
	}
	function mintRoninClick(){
		mintRonin(1);
	}
	function mint10RoninClick(){
		mintRonin(10);
	}

	function claimRoninClick(){
		claimRonin(1);
	}
	function reserveRoninClick() {
		Contract.ronin.reserve(1)
	}

	let show = true;
	let status = ""

	// $: {
	// 	show === true ? status = "hide" : status = "show"
	// }


</script>

<div class="mt-10 {show ? 'debugger' : 'debugger_collapsed'}">
	<button class="base-btn" on:click={toggle}>{status}</button>
	<div class="{show ? '': 'hide'}">
		<div>use alchemy: {$useAlchemy}</div>
		<div>state: {$state}</div>
		{#if $state === "loading"}
			<div style="color:red; font-weight: bold">
				LOADING...
			</div>
		{:else}
			{#if $useAlchemy}
				<div>
					<button on:click={doUnlock}>
						Unlock
					</button>
				</div>
			{:else if $state === "loaded"}
				<div>
					<button on:click={doLock}>
						LOCK
					</button>
				</div>
				{#if !$data.roninPaused.value}
					<div>
						<button on:click={mintRoninClick} style="color:red; font-weight: bold">
							Mint a Ronin
						</button>
					</div>

					<div>
						<button on:click={mint10RoninClick} style="color:red; font-weight: bold">
							Mint 10 Ronin
						</button>
					</div>


					<div>
						<button on:click={reserveRoninClick}>
							Reserve a Ronin
						</button>
					</div>
					{#if $data.myRoninReserveCount.value > 0}
						<div>
							<button on:click={claimRoninClick}>
								Claim a Ronin
							</button>
						</div>
						{/if}
					{/if}
				{/if}
			{#each keys as key}
			<div>
				{key}:
				{#if typeof $data[key].value === "object"}
				{JSON.stringify($data[key].value)}
				{:else}
				{$data[key].value}
				{/if}
			</div>
			{/each}
		{/if}
		<div>
			-----
		</div>
	</div>
</div>