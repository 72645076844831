<script>
  import {Route} from 'svelte-router-spa'
  import Header from '../components/shared/Header.svelte';
  import EntryHeader from '../components/shared/EntryHeader.svelte';
  import Footer from '../components/shared/Footer';

  export let currentRoute;

  const params = {};

  let w;
  let y;
  let screenHeight;

</script>

<svelte:window bind:scrollY={y} bind:innerHeight={screenHeight} bind:innerWidth={w} />

<div class="app">
  <!-- Header Conditions Based on Routes -->
  {#if y > 550 && currentRoute.path === "/"}
    <Header {currentRoute} />
  {:else if w > 500 && y < 549 && currentRoute.path === "/"}
    <EntryHeader {currentRoute} />
  {:else if currentRoute.path !== "/"}
    <Header {currentRoute} />
  {:else if w < 500}
    <Header {currentRoute} />
  {/if}
  <!-- Main Content  -->
  <Route {currentRoute} {params} />
  <!-- Show Footer only on Home Screen  -->
  {#if currentRoute.path === "/"}
    <Footer/>
  {/if}
</div>
