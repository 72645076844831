<script>
	import {onMount} from 'svelte';
	import {useAlchemy, state, data} from '../stores/manager'
	import {fade} from 'svelte/transition';
	import HazeLayer from '../components/shared/HazeLayer.svelte'
	import {fetchIPFSProfile, profile} from '../stores/metadata.js';
	import {mountedForAscension, identifyRonin, isGold} from '../stores/transactions';
	import {toggleZoom, setEnhancement} from '../stores/ui'
	import Spinner from '../components/shared/Spinner.svelte';
	import BattlegroundLayer from '../components/shared/BattlegroundLayer.svelte';

	import {navigateTo} from 'svelte-router-spa';
	import {get} from "svelte/store";
	import {metadata, OPENSEA_STEM, selectedKitsune, selectKitsune,requestKitsuneMetadata} from "../stores/kitsuneMetadata";

	export let currentRoute;




	let w;
	let tokenId = currentRoute.namedParams.id;

	requestKitsuneMetadata(tokenId);
	selectKitsune(tokenId);

	function capitalise(_string){
		if(_string.length === 0){
			return "";
		}else if(_string.length === 1){
			return _string.toUpperCase();
		}else{
			return _string[0].toUpperCase() + _string.substr(1);
		}
	}

	let _metadata = get(metadata)[tokenId];

	let img      = _metadata.name;
	let name     = _metadata.image;
	let traits   = _metadata.attributes;

	$:{
		// tokenId = $selectedKitsune;

		if($metadata[tokenId]){
			_metadata = $metadata[tokenId];

			name = _metadata.name;
			img = _metadata.image;
			traits = _metadata.attributes;
		}

	}


	let zoom = false;
	$: $setEnhancement === true ? zoom = true : zoom = false;

	function handleZoom(){
		toggleZoom();
	}

	const newWindow = (url) => { window.open(url) };
	const clickForOpenSea = () => { newWindow( OPENSEA_STEM + tokenId ) };


</script>

<svelte:window bind:innerWidth={w}/>

<svelte:head>
	<!-- Hotjar Tracking Code for https://onironin.io/ --> 
	<script> 
		(function(h,o,t,j,a,r){
			h.hj=h.hj||function(){
				(h.hj.q=h.hj.q||[]).push(arguments)
				};
				h._hjSettings={hjid:2922448,hjsv:6};
				a=o.getElementsByTagName('head')[0];
					r=o.createElement('script');
					r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r);
					})
				(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
	</script>
</svelte:head>

<div class="general_layout">
	<HazeLayer/>
	<BattlegroundLayer/>
	{#if img}
	<div class="fog_container profile_container" in:fade>
		<div class="profile_nav_row">

		</div>



		<div class="{w > 600 ? 'profile_columns':'profile_rows'}">
			<div class="{w > 600 ? 'left_col':'left_col_mobile mt-32'}">
				{#if w < 600}
					<h1 class="ronin_title_mobile">KITSUNE</h1>
					<h1 class="section_highlight section_tagline_center mb-5 animate-pulse duration-1000">
						#{tokenId}
					</h1>
				{/if}
				<img id="currentRonin" src={img} alt={name} class="{w > 600 ? 'ronin_img':'ronin_img_responsive'}{zoom === true ? 'ronin_img_hidden':''}" on:click={handleZoom}/>
		<!-- Modal -->
			<div id="myModal" class="{zoom ? 'modal_open': 'modal'}">
				<img class="modal-content" id="img01" src={img} alt={name} on:click={handleZoom}>
				<div id="caption">

				</div>
		<!-- Modal Ends -->
			</div>
			</div>
			<div class="right_col text-left">
				{#if w > 600}
					<h1 class="ronin_title">KITSUNE</h1>
					<h1 class="section_highlight section_tagline animate-pulse duration-1000">
						#{tokenId}
					</h1>
				{/if}
				{#if w > 600}
					<p class="fine_print text-sm text-gray-600 animate-pulse duration-1000">Available Actions</p>

						<div class="-ml-3">
							<button on:click={clickForOpenSea} class="base-btn-asc-alt hover:animate-pulse duration-1000">VIEW ON OPENSEA</button>
							<button on:click={toggleZoom} class="base-btn-asc-alt-mobile">ENHANCE</button>
						</div>

				{:else}
					<div class="text-center profile_controls_mobile mb-10">
						<p class="fine_print text-center text-sm text-gray-600 animate-pulse duration-1000 -mt-8">Available Actions</p>

						<button on:click={toggleZoom} class="base-btn-asc-alt-mobile">ENHANCE</button>
						<button on:click={clickForOpenSea} class="base-btn-asc-alt-mobile">VIEW ON OPENSEA</button>
					</div>
				{/if}
				{#if traits}
					<div class="{w > 600 ? 'attr':'mobile_attr'} -mt-10">
							{#each traits as attributes}
								<p class="mb-1 {w > 600 ? '':''}">
									<span class="attr_type mr-1">{capitalise(attributes.trait_type)}:</span>
									<span class="attr_value">{attributes.value}</span>
								</p>
							{/each}
					</div>
				{/if}
			</div>
		</div>
	</div>
	{/if}
</div>

<style>

	.ronin_img {
		height: 500px;
		width: 500px;
		margin: 0 auto;
		z-index: 5;
		outline: 2px solid gold;
		background: black;
	}

	.left_col {
		display: flex;
		flex-direction: column;
		align-self: center;
		margin-bottom: 1em;
		margin-right: 2em;
		height: 500px;
		width: 500px;
	}
	.left_col_mobile {
		display: flex;
		flex-direction: column;
		align-self: center;
		margin-bottom: 1em;
		height: 500px;
		width: auto;
	}
	.right_col {
		align-items: center;
		justify-content: center;
	}

</style>