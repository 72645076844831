import NotFound from "./../pages/NotFound.svelte";
import Home from "./../pages/Home.svelte";
import Mint from "./../pages/Mint.svelte";
import Ascend from "./../pages/Ascend.svelte";
import Roadmap from "./../pages/Roadmap.svelte";
import Layout from "./__layout.svelte";
import Kitsune from "./../pages/Kitsune.svelte";
import YourNFTs from "./../pages/YourNFTs.svelte";
import YourNFTbyID from "./../pages/YourNFTbyID.svelte";
import KitsuneByID from "./../pages/KitsuneByID.svelte";
import BoatGame from "./../pages/BoatGame.svelte";
import Debug from "./../pages/Debug.svelte";

const routes = [
	{
		name: "404",
		path: "404",
		component: NotFound,
	},
	{
		name: "/",
		layout: Layout,
		component: Home,
	},
	{
		name: "Mint",
		layout: Layout,
		component: Mint,
	},
	{
		name: "Ascend",
		layout: Layout,
		component: Ascend,
	},
	{
		name: "Roadmap",
		layout: Layout,
		component: Roadmap,
	},
	{
		name: "Kitsune/:claimId",
		path: "/Kitsune/:claimId",
		layout: Layout,
		component: Kitsune,
	},
	{
		name: "YourNFTs",
		layout: Layout,
		component: YourNFTs,
	},
	{
		name: "ronin/:id",
		path: "/ronin/:id",
		layout: Layout,
		component: YourNFTbyID,
		/* ADD PARAMS TO THIS ROUTE*/
	},
	{
		name: "kitsuneDetail/:id",
		path: "/kitsuneDetail/:id",
		layout: Layout,
		component: KitsuneByID,
		/* ADD PARAMS TO THIS ROUTE*/
	},

	{
		name: "BoatGame",
		layout: Layout,
		component: BoatGame,
	},

	{
		name: "Debug",
		layout: Layout,
		component: Debug,
	},


];

export { routes };
